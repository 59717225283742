<template>
  <div class="containner" :class="is_mobile ? 'm-containner' : 'pc-containner'">
    <div class="pro-nav-bar" :class="nav_is_fixed ? 'pro-nav-bar-fixed' : ''">
      <img class="pro-logo-img" :src="pro_log" />
      <div class="pro-nav-wrap">
        <div class="pro-icon">
          <div
            class="pro-it-btn"
            :class="nav_act == 1 ? 'pro-it-btn-act' : ''"
            @click="scrollToSummarize"
          >
            {{ $t("pro.aikit.nav.summarize") }}
          </div>
          <div
            class="pro-it-btn"
            :class="nav_act == 2 ? 'pro-it-btn-act' : ''"
            @click="scrollToAdvantage"
          >
            {{ $t("pro.aikit.nav.advantage") }}
          </div>
        </div>
        <div class="pro-cooperation" @click="showCooparationForm">
          {{ $t("pro.aikit.nav.coopearation") }}
        </div>
      </div>
    </div>
    <div class="banner" id="summarize">
      <img class="banner-bg" :src="is_mobile ? banner_m : banner" />
      <div class="pro-series-content">
        <div class="pro-series-name">{{ $t("pro.aikit.name") }}</div>
        <div class="pro-series-desc">{{ $t("pro.aikit.description") }}</div>
      </div>
    </div>
    <div class="performance-wrap">
      <img class="perform-bg" :src="is_mobile ? perform_bg_m : perform_bg" />
      <div class="perform-box">
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[0].icon" />
          <div class="perform-tit">{{ this.$t("pro.aikit.perform_a") }}</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[1].icon" />
          <div class="perform-tit">{{ this.$t("pro.aikit.perform_b") }}</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[2].icon" />
          <div class="perform-tit">{{ this.$t("pro.aikit.perform_c") }}</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[3].icon" />
          <div class="perform-tit">{{ this.$t("pro.aikit.perform_d") }}</div>
        </div>
        <div class="perform-list">
          <img class="perform-icon" :src="perform_list[4].icon" />
          <div class="perform-tit">{{ this.$t("pro.aikit.perform_e") }}</div>
        </div>
      </div>
    </div>
    <div class="pro-advantage-box" id="advantage">
      <div class="pro-ad-list ani-tl-0">
        <cx-pro-advantage
          :bg="is_mobile ? advantage_list[0].bg_m : advantage_list[0].bg"
          :title="$t('pro.aikit.advantage.adv_a_title')"
          :description="$t('pro.aikit.advantage.adv_a_desc')"
        ></cx-pro-advantage>
      </div>
      <div class="pro-ad-list ani-tl-1">
        <cx-pro-advantage
          :bg="is_mobile ? advantage_list[1].bg_m : advantage_list[1].bg"
          :title="$t('pro.aikit.advantage.adv_b_title')"
          :description="$t('pro.aikit.advantage.adv_b_desc')"
        ></cx-pro-advantage>
      </div>
      <div class="pro-ad-list ani-tl-2">
        <cx-pro-advantage
          :bg="is_mobile ? advantage_list[2].bg_m : advantage_list[2].bg"
          :title="$t('pro.aikit.advantage.adv_c_title')"
          :description="$t('pro.aikit.advantage.adv_c_desc')"
        ></cx-pro-advantage>
      </div>
      <div class="pro-ad-list ani-tl-3">
        <cx-pro-advantage
          :bg="is_mobile ? advantage_list[3].bg_m : advantage_list[3].bg"
          :title="$t('pro.aikit.advantage.adv_d_title')"
          :description="$t('pro.aikit.advantage.adv_d_desc')"
        ></cx-pro-advantage>
      </div>
      <div class="pro-ad-list ani-tl-4">
        <cx-pro-advantage
          :bg="is_mobile ? advantage_list[4].bg_m : advantage_list[4].bg"
          :title="$t('pro.aikit.advantage.adv_e_title')"
          :description="$t('pro.aikit.advantage.adv_e_desc')"
        ></cx-pro-advantage>
      </div>
    </div>
    <div class="aikit-data-box">
      <img class="aikit-data-bg" :src="is_mobile ? pro_data_bg_m : pro_data_bg" />
      <div class="aikit-data-wrap">
        <div class="aikit-d-box">
          <div class="aikit-data-item">
            <div class="akit-lable">{{ $t("pro.aikit.response_time") }}</div>
            <div class="akit-data">-50<span>%</span></div>
            <img class="aikit-d-img" :src="narrow_icon_down" />
          </div>
          <div class="aikit-data-item">
            <div class="akit-lable">{{ $t("pro.aikit.human_investion") }}</div>
            <div class="akit-data">-50<span>%</span></div>
            <img class="aikit-d-img" :src="narrow_icon_down" />
          </div>
          <div class="aikit-data-item">
            <div class="akit-lable">{{ $t("pro.aikit.learning_cost") }}</div>
            <div class="akit-data">-50<span>%</span></div>
            <img class="aikit-d-img" :src="narrow_icon_down" />
          </div>
          <div class="aikit-data-item">
            <div class="akit-lable">{{ $t("pro.aikit.iteration_efficient") }}</div>
            <div class="akit-data akit-data-up">-30<span>%</span></div>
            <img class="aikit-d-img" :src="narrow_icon_up" />
          </div>
          <div class="aikit-data-item">
            <div class="akit-lable">{{ $t("pro.aikit.deploy_efficient") }}</div>
            <div class="akit-data akit-data-up">-20<span>%</span></div>
            <img class="aikit-d-img" :src="narrow_icon_up" />
          </div>
        </div>
      </div>
    </div>
    <cx-contact @showCoopationForm="showCooparationForm"></cx-contact>
    <cx-cover-layout v-if="show_cooparation_form">
      <cx-cooperation
        source="AIKit"
        @hideCooparationForm="hideCooparationForm"
      ></cx-cooperation>
    </cx-cover-layout>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Contact from "../../components/Contact.vue";
import ProAdvantage from "../../components/ProAdvantage.vue";
import Cooperation from "../../components/Cooperation.vue";
import CoverLayout from "../../components/CoverLayout.vue";
gsap.registerPlugin(ScrollToPlugin);
export default {
  name: "RazorAIKit",
  components: {
    "cx-contact": Contact,
    "cx-pro-advantage": ProAdvantage,
    "cx-cooperation": Cooperation,
    "cx-cover-layout": CoverLayout,
  },
  props: {},
  data() {
    return {
      nav_act: 1,
      show_cooparation_form: false,

      narrow_icon_down: require("@/assets/narrow-down.png"),
      narrow_icon_up: require("@/assets/narrow-up.png"),

      pro_data_bg: require("@/assets/pro-aikit-data-bg.png"),
      pro_data_bg_m: require("@/assets/pro_data_bg_m.png"),
      pro_log: require("@/assets/pro-aikit-logo.png"),
      banner: require("@/assets/aikit-banner.png"),
      banner_m: require("@/assets/aikit-banner-m.png"),

      series_case: require("@/assets/series-razordcx-takla.png"),
      perform_bg: require("@/assets/i-bg-a.png"),
      perform_bg_m: require("@/assets/series-bg.png"),

      advantage_list: [
        {
          bg: require("@/assets/aikit-det-a.png"),
          bg_m: require("@/assets/aikit-det-a-m.png"),
        },
        {
          bg: require("@/assets/aikit-det-b.png"),
          bg_m: require("@/assets/aikit-det-b-m.png"),
        },
        {
          bg: require("@/assets/aikit-det-c.png"),
          bg_m: require("@/assets/aikit-det-c-m.png"),
        },
        {
          bg: require("@/assets/aikit-det-d.png"),
          bg_m: require("@/assets/aikit-det-d-m.png"),
        },
        {
          bg: require("@/assets/aikit-det-e.png"),
          bg_m: require("@/assets/aikit-det-e-m.png"),
        },
      ],

      perform_list: [
        {
          icon: require("@/assets/aikit-icon-a.png"),
        },
        {
          icon: require("@/assets/aikit-icon-b.png"),
        },
        {
          icon: require("@/assets/aikit-icon-c.png"),
        },
        {
          icon: require("@/assets/aikit-icon-d.png"),
        },
        {
          icon: require("@/assets/aikit-icon-e.png"),
        },
      ],

      nav_is_fixed: false,

      tl: null,
      tl3: null,
    };
  },
  methods: {
    hideCooparationForm() {
      this.show_cooparation_form = false;
    },
    showCooparationForm() {
      this.show_cooparation_form = true;
    },
    scrollToSummarize() {
      let off_set = 30;
      if (this.is_mobile) {
        off_set = 50;
      }
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#summarize", offsetY: off_set } });
      this.nav_act = 1;
    },
    scrollToAdvantage() {
      gsap.to(window, { duration: 0.5, scrollTo: { y: "#advantage", offsetY: 100 } });
      this.nav_act = 2;
    },
  },
  destroyed() {
    this.tl.kill();
    this.tl3.kill();
    this.tl = null;
    this.tl3 = null;
  },
  mounted() {
    let that = this;
    window.addEventListener("scroll", function () {
      const scroll_top = that.getScrollTop();
      if (scroll_top < that.rate * 100) {
        that.nav_is_fixed = false;
      } else {
        that.nav_is_fixed = true;
      }
      if (!that.is_mobile) {
        if (scroll_top < that.rate * 1140) {
          that.nav_act = 1;
        } else if (scroll_top >= that.rate * 1140) {
          that.nav_act = 2;
        }
      } else {
        if (scroll_top < that.rate * 1940) {
          that.nav_act = 1;
        } else if (scroll_top >= that.rate * 1940) {
          that.nav_act = 2;
        }
      }
    });

    this.tl = gsap.timeline({});
    this.tl
      .addLabel("start")
      .from(".banner-bg", {
        opacity: 0,
        x: 100,
      })
      .from(".pro-series-name", {
        opacity: 0,
        y: 50,
      })
      .from(".pro-series-desc", {
        opacity: 0,
        y: 50,
      })
      .from(".pro-series-btn", {
        opacity: 0,
        y: 50,
      })
      .from(
        ".perform-list",
        {
          opacity: 0,
          duration: 0.5,
          y: 50,
        },
        "<"
      )
      .from(".perform-tit", {
        opacity: 0,
        delay: -0.3,
        y: 50,
      });

    let tl3_start = 100;

    this.tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".pro-advantage-box",
        pin: true,
        start: "-" + tl3_start + " top",
        end: "+=1000",
        scrub: 0,
      },
    });

    let trans_scale = this.rate * 650;
    this.tl3
      .addLabel("begin")
      .to(".ani-tl-0", { opacity: 1, y: 0, scale: 0.95 })
      .to(".ani-tl-1", { opacity: 1, y: -trans_scale * 1 }, "<")
      .to(".ani-tl-2", { opacity: 1, y: -trans_scale * 1 }, "<")
      .to(".ani-tl-3", { opacity: 1, y: -trans_scale * 1 }, "<")
      .to(".ani-tl-4", { opacity: 1, y: -trans_scale * 1 }, "<")

      .to(".ani-tl-0", { opacity: 1, y: 0, scale: 0.85 })
      .to(".ani-tl-1", { opacity: 1, y: -trans_scale * 1, scale: 0.95 }, "<")
      .to(".ani-tl-2", { opacity: 1, y: -trans_scale * 2 }, "<")
      .to(".ani-tl-3", { opacity: 1, y: -trans_scale * 2 }, "<")
      .to(".ani-tl-4", { opacity: 1, y: -trans_scale * 2 }, "<")

      .to(".ani-tl-1", { opacity: 1, y: -trans_scale * 1, scale: 0.85 })
      .to(".ani-tl-2", { opacity: 1, y: -trans_scale * 2, scale: 0.95 }, "<")
      .to(".ani-tl-3", { opacity: 1, y: -trans_scale * 3 }, "<")
      .to(".ani-tl-4", { opacity: 1, y: -trans_scale * 3 }, "<")

      .to(".ani-tl-2", { opacity: 1, y: -trans_scale * 2, scale: 0.85 })
      .to(".ani-tl-3", { opacity: 1, y: -trans_scale * 3, scale: 0.95 }, "<")
      .to(".ani-tl-4", { opacity: 1, y: -trans_scale * 4 }, "<");
  },
};
</script>
<style lang="less" scoped>
.pro-advantage-box {
  margin: 120px 0px 0px;
  height: 700px;
}

.pro-nav-bar {
  position: absolute;
  top: 0px;
  height: 86px;
  width: 100%;
  z-index: 111;
  background-color: rgba(36, 36, 36, 1);
}
.pro-nav-bar-fixed {
  position: fixed;
  top: 0px;
  z-index: 1111;
}
.pro-ad-list {
  position: relative;
  width: 1300px;
  height: 650px;
  margin: 0 auto;
}
.ani-tl-0 {
  z-index: 101;
}

.ani-tl-1 {
  z-index: 102;
}

.ani-tl-2 {
  z-index: 103;
}

.ani-tl-3 {
  z-index: 104;
}

.ani-tl-4 {
  z-index: 105;
}

.ani-tl-5 {
  z-index: 106;
}
.pro-logo-img {
  position: absolute;
  height: 84px;
  left: 223px;
}
.pro-nav-wrap {
  position: absolute;
  right: 230px;
  top: 26px;
}
.pro-it-btn {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  display: inline-block;
  color: #fff;
  margin-left: 90px;
  border-bottom: 1px solid rgba(36, 36, 36, 1);
  transition: 300ms linear all;
  cursor: pointer;
  // box-shadow: 0px 2px 4px 0px rgba(0, 183, 255, 1);
}
.pro-it-btn:hover {
  border-bottom: 1px solid rgba(51, 146, 255, 1);
}
.pro-it-btn-act {
  border-bottom: 1px solid rgba(51, 146, 255, 1);
}
.pro-cooperation {
  display: inline-block;
  padding: 0px 10px;
  height: 35px;
  line-height: 35px;
  opacity: 1;
  font-size: 16px;
  margin-left: 90px;
  font-weight: 400;
  height: 35px;
  cursor: pointer;
  color: rgba(0, 0, 0, 1);
  border-radius: 3.19px;
  background: rgba(144, 218, 252, 1);
}
.pro-icon {
  display: inline-block;
}
.containner {
  padding-top: 86px;
  position: relative;
  overflow: hidden;
  margin-top: -3px;
}
.banner {
  position: relative;
  height: 587px;
}
.banner-bg {
  position: absolute;
  width: 1920px;
  height: 587px;
  z-index: 0;
  left: 0px;
  top: 0px;
}
.pro-series-content {
  position: absolute;
  color: #fff;
  z-index: 11;
  top: 93px;
  left: 230px;
}
.pro-series-name {
  font-size: 44px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 29px;
}
.pro-series-desc {
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  width: 740px;
  text-align: left;
}
.performance-wrap {
  position: relative;
  height: 530px;
  overflow: hidden;
}
.perform-bg {
  position: absolute;
  z-index: 0;
  left: 0px;
  bottom: 0px;
  width: 1920px;
  height: 709px;
}
.perform-box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 11;
}
.perform-list {
  margin-top: 152px;
  padding: 0px 50px;
}
.perform-icon {
  width: 101px;
  height: 101px;
}
.perform-tit {
  text-align: center;
  width: 184px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  margin-top: 21px;
}
.aikit-data-box {
  position: relative;
  width: 1927px;
  height: 690px;
  z-index: 1;
}
.aikit-data-bg {
  position: absolute;
  width: 1927px;
  height: 690px;
  top: 0px;
  left: 0px;
  z-index: 111;
}

.aikit-data-wrap {
  position: absolute;
  width: 1927px;
  height: 690px;
  top: 0px;
  left: 0px;
  z-index: 111;
}

.aikit-d-box {
  text-align: center;
  width: 1200px;
  margin: 120px auto;
}
.aikit-data-item {
  position: relative;
  display: inline-block;
  width: 300px;
  margin: 30px 40px;
}
.akit-lable {
  font-size: 21.6px;
  font-weight: 400;
  letter-spacing: 8.1px;
  line-height: 40.5px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0;
  white-space: nowrap;
}
.akit-data {
  font-size: 72px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 63px;
  color: rgba(0, 163, 225, 1);
}
.akit-data-up {
  color: rgba(255, 141, 26, 1);
}

.akit-data span {
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 1.8px;
  line-height: 63px;
  color: rgba(0, 163, 225, 1);
}
.akit-data-up span {
  color: rgba(255, 141, 26, 1);
}
.aikit-d-img {
  position: absolute;
  width: 56px;
  height: 111px;
  right: -10px;
  top: 0px;
}
.m-containner {
  padding-top: 180px;
  .banner {
    position: relative;
    height: auto;
    overflow: hidden;
    height: 1118px;
    .banner-bg {
      width: 750px;
      height: 1118px;
    }
    .pro-series-content {
      width: 100%;
      text-align: center;
      left: 0px;
      .pro-series-name {
        width: 620px;
        font-size: 50px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
      .pro-series-desc {
        width: 620px;
        font-size: 25px;
        margin: 0 auto;
      }
    }
  }
  .performance-wrap {
    width: 750px;
    overflow: hidden;
    height: 634px;
    padding-top: 40px;
    .perform-bg {
      width: 750px;
      height: 674px;
    }
    .perform-box {
      padding-top: 40px;
      .perform-list {
        padding: 10px;
        margin-top: 60px;
      }
    }
  }
  .pro-nav-bar {
    height: 180px;
    .pro-logo-img {
      left: 35px;
    }
    .pro-cooperation {
      width: 130px;
      height: 45px;
      line-height: 45px;
      font-size: 24px;
      text-align: center;
    }
    .pro-nav-wrap {
      width: 100%;
      right: 23px;
      text-align: right;
      .pro-icon {
        position: absolute;
        width: 700px;
        left: 0px;
        text-align: left;
        top: 65px;
        font-size: 24px;
        .pro-it-btn {
          font-size: 27px;
          padding: 10px 0px;
        }
      }
    }
  }

  .pro-advantage-box {
    height: 750px;
    .pro-ad-list {
      width: 750px;
    }
  }
  .aikit-data-box {
    width: 750px;
    height: 580px;
    .aikit-data-wrap {
      width: 750px;
      height: 580px;
      .aikit-d-box {
        width: 720px;
        float: left;
        .aikit-data-item {
          margin-left: 0px;
          width: 220px;
          margin-right: 10px;
          .akit-data {
            font-size: 63px;
          }
          .akit-lable {
            font-size: 25px;
          }
        }
      }
    }
    .aikit-data-bg {
      width: 750px;
      height: 580px;
    }
  }
}
</style>
